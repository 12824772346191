html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

/* Colors */

.colorWhite {
  color: #ffffff;
}

.colorOrange {
  color: #ec7108;
}

.colorBlue {
  color: #278bc0;
}

.colorGrey {
  color: #7c8387;
}

.colorBlack {
  color: black;
}

.colorHeroku {
  color: #430098;
}

.colorDigitalO {
  color: #0080ff;
}

.colorMongo {
  color: #47a248;
}

.colorReact {
  color: #61dafb;
}

.colorJS {
  color: #f7df1e;
}

.colorNode {
  color: #339933;
}

.colorPostgre {
  color: #336791;
}

.colorApache {
  color: #d22128;
}

.colorHTML {
  color: #e34f26;
}

.colorCSS {
  color: #1572b6;
}

.colorPython {
  color: #3776ab;
}

.colorNginx {
  color: #269539;
}

.colorGit {
  color: #f05032;
}

.colorBootstrap {
  color: #7952b3;
}

.colorMaterialUI {
  color: #0081cb;
}

.colorScrum {
  color: #415970;
}

.colorEar {
  color: #7c8387;
}

.bgGrey {
  background-color: #f9f9f9;
}

.bgBlue {
  background-color: #278bc0;
}

.bgOrange {
  background-color: #ec7108 !important;
}

.bgBlack {
  background-color: black;
}

.bgGray {
  background-color: rgba(249, 249, 249, 0.65) !important;
}

.fontWhite {
  color: #ffffff;
}

.colorOrange :hover {
  color: #278bc0;
}

.hyperlinkColor {
  color: #ec7108;
}

.MuiTypography-body1 a:hover {
  color: #278bc0 !important;
}

.customCursor {
  cursor: url(./pages/homePage/assets/bVSCursor.png), auto !important;
  border: #278bc0 2px !important;
}

/* Font */

body,
p {
  font-family: "Exo 2", sans-serif;
  font-size: clamp(12px, 1.75vw, 22px);
  /* font-size: min 1.75vw; */
}

.accordianFont {
  font-size: clamp(10px, 1.25vw, 18px);
}

h1,
h2,
h3,
h4 {
  font-family: "Titillium Web", sans-serif;
  margin: 0;
}

/* Styling Layout */

.flex {
  display: flex;
}

.flexWrap {
  flex-wrap: wrap;
}

.inlineFlex {
  display: inline-flex;
}

.justifyCenter {
  justify-content: center;
}

.justifySpaceAround {
  justify-content: space-around;
}

.alignCenter {
  align-items: center;
}

.textCenter {
  text-align: center;
}

.paddingZero {
  padding: 0;
}

.paddingTen {
  padding: 0.5%;
}

.paddingTenHorizontal {
  padding: 0 0.5% 0 0.5%;
}

.paddingTwentyHorizontal {
  padding: 0 0.5% 0 0.5%;
}

.paddingTenVertical {
  padding: 0.5% 0 0.5% 0;
}

.paddingTwenty {
  padding: 1%;
}

.paddingTwentyHorizontal {
  padding: 0 1% 0 1%;
}

.paddingTwentyVertical {
  padding: 1% 0 1% 0;
}

.paddingTopForty {
  padding: 5% 0 0 0;
}

.listStyleNone {
  list-style: none;
}

.h2Padding {
  padding: 2% 0;
}

.marginZero {
  margin: 0px;
}

.marginAuto {
  margin: auto;
}

.marginVerticalFive {
  margin: 0.1rem 0 0.1rem 0;
}

.marginVerticalTwenty {
  margin: 1% 0 1% 0 !important;
}

.MuiTypography-body1 {
  margin: auto !important;
}

.widthStylining {
  width: 75%;
  justify-content: space-around;
}

.widthFifty {
  width: 50%;
}

.textDecorationNone {
  text-decoration: none;
}

/* Nav */

.navLinkStyling {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navLogo {
  border-radius: 35%;
  height: 35%;
  width: 35%;
}

.site-nav a {
  color: #ec7108 !important;
}

.site-nav a:hover {
  color: #278bc0 !important;
}

.navLinkPadding {
  padding-left: 0 0.8% 0 0.8%;
}

/* Banner */

.mainBanner {
  background-size: cover;
  background-position: bottom center;
  background-image: url("../src/components/Images/banner2.jpg");
  margin-bottom: 0;
  width: 100vw;
  height: 90vh;
}

/* About  */
.profileImg {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  height: 10%;
  width: 20%;
  margin-bottom: 2%;
}

.brand {
  display: flex;
  justify-content: space-around;
  padding: 1%;
}

.brand a:hover {
  color: #278bc0;
}

/* Education Resume */

.sectionPBottom {
  padding-bottom: 15%;
}

/* Portfolio */
.MuiGridListTile-root {
  height: 50vh !important;
}

.portfolioTileImg {
  margin: 10px;
  width: 45vw;
  height: 50vh;
  padding: 60px 0;
}

.MuiGridListTileBar-actionIcon {
  padding: 1% !important;
}

.MuiGridListTileBar-title {
  color: #ec7108 !important;
}

/* skills  */
.progressPadding {
  margin: 1% 0px 1% 0px;
}

.accordionStyles {
  color: #ec7108;
  font-size: large;
}

.accordionStyles:hover {
  color: #278bc0;
}

.listStyling {
  padding: 0px;
}

.progressCollapseFontSize {
  font-size: medium;
}

.iconStyles {
  padding: 1% 3% 1% 3%;
}

/* .MuiTabs-root {
  display: block !important;
} */

.MuiIconButton-root {
  padding: 0px !important;
}

.MuiTabs-indicator {
  background-color: #ec7108 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #ec7108 !important;
}

.MuiButtonBase-root :hover,
.MuiTab-textColorPrimary :hover {
  color: #278bc0 !important;
}
/* Contact */

.form-error,
.form-i-right i,
.form-success {
  color: #ffffff;
}

.serviceWidth {
  min-width: 194.8px;
}

.serviceFont {
  font-size: clamp(12px, 1.75vw, 22px);
}

.MuiTextField-root {
  width: 58ch !important;
}

@media only screen and (max-width: 550px) {
  .MuiGridListTile-root {
    width: 100% !important;
  }
  .MuiTextField-root {
    width: 45ch !important;
  }
  .serviceFont {
    font-size: clamp(12px, 1.75vw, 22px);
  }
}
